import * as React from "react"
import ResponsiveImage, { ResponsiveImageSource } from "../responsive-image"
import logo from "./logo.png"
import logoAlt from "./logo-alt.png"

interface LogoProps {
  width?: number
  className?: string
  alt: string
  type?: "logo" | "logo-alt"
}

export default function Logo({
  width,
  alt,
  className,
  type = "logo",
}: LogoProps) {
  const logoToUse = type === "logo" ? logo : logoAlt
  return (
    <ResponsiveImage
      width={width}
      alt={alt}
      src={logoToUse}
      className={className}
    >
      <ResponsiveImageSource
        breakpoints={{
          "0px": "1000px",
        }}
        imageSizes={{
          "1000w": logoToUse,
        }}
      />
    </ResponsiveImage>
  )
}
